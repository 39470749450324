import React, { useContext, useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { useParams } from 'react-router';
import FiltersAndActions from '../../Components/Orders/FiltersAndActions';
import { BASE_URL_API } from '../../References/url';
import AuthContext from '../../Store/auth-context';
import styles from './AllOrders.module.css';

const AllOrders = () => {
    const { orderIdentifier } = useParams();
    const [allOrders, setAllOrders] = useState([]);
    const authCtx = useContext(AuthContext);
    const [filteredData, setFilteredData] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [filterCurrentStatus, setFilterCurrentStatus] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    const getAllOrderForTracking = async () => {
        const response = await fetch(BASE_URL_API + "/getAllOrderForTracking", {
            method: 'POST',
            body: JSON.stringify({
                token: authCtx.token,
                orderIdentifier
            })
        });

        if (!response.ok) {
            console.log("Something went wrong: Server Error");
        } else {
            const result = await response.json();
            if (result.status === "success") {
                setAllOrders(result.response);
                setFilteredData(result.response);
            } else if (result.message === 'token Expired, please login again!') {
                authCtx.logout();
            } else {
                setAllOrders([]);
            }
        }
    };

    useEffect(() => {
        getAllOrderForTracking();
    }, []);

    const filterData = () => {
        setFilteredData(
            allOrders.filter(item => {
                // Matches if filterCurrentStatus is empty or includes the current status
                const matchesStatus =
                    !filterCurrentStatus.length ||
                    filterCurrentStatus.includes(item.currentStatus);

                // Matches if searchKey is empty or found in any item field
                const matchesSearch =
                    !searchKey ||
                    Object.values(item).some(value =>
                        value?.toString().toLowerCase().includes(searchKey.toLowerCase())
                    );

                return matchesStatus && matchesSearch;
            })
        );
    };

    useEffect(() => {
        filterData();
    }, [allOrders, searchKey, filterCurrentStatus]);

    const updateFilterCurrentStatus = (status) => {
        if (status === 'All') {
            setFilterCurrentStatus([]);
        } else if (filterCurrentStatus.includes(status)) {
            setFilterCurrentStatus(filterCurrentStatus.filter(item => item !== status));
        } else {
            setFilterCurrentStatus([...filterCurrentStatus, status]);
        }
    };


    // const downloadCSV = () => {
    //     const csvRows = [];

    //     // Get the headers
    //     const headers = Object.keys(allOrders[0]);
    //     csvRows.push(headers.join(','));

    //     // Loop over the rows
    //     allOrders.forEach(row => {
    //         const values = headers.map(header => {
    //             const escaped = ('' + row[header]).replace(/"/g, '\\"');
    //             return `"${escaped}"`;
    //         });
    //         csvRows.push(values.join(','));
    //     });

    //     // Create a Blob from the CSV rows and download it
    //     const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.setAttribute('hidden', '');
    //     a.setAttribute('href', url);
    //     a.setAttribute('download', `invoice_data_${Date.now()}.csv`);
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    // };


    const downloadCSV = (selectedColumns = null, startRow = 0, endRow = allOrders.length) => {
        const csvRows = [];

        // Determine headers based on selectedColumns or all columns if selectedColumns is null
        const headers = selectedColumns && selectedColumns.length > 0
            ? selectedColumns
            : Object.keys(allOrders[0]);
        csvRows.push(headers.join(','));

        // Limit rows based on the specified range (first row is always the header)
        const limitedRows = allOrders.slice(startRow, endRow);

        // Loop over the rows and add only the selected columns
        limitedRows.forEach(row => {
            const values = headers.map(header => {
                const escaped = ('' + (row[header] ?? '')).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        });

        // Create a Blob from the CSV rows and download it
        const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `${orderIdentifier}_delivery_data_${Date.now()}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ECFAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ECFAFF";
            document.head.appendChild(meta);
        }
    }, []);



    return (
        <div className={styles.mainWrapper}>
            {isFilterOpen && <FiltersAndActions 
                isOpen={isFilterOpen} 
                onClose={() => setIsFilterOpen(false)} 
                filterCurrentStatus={filterCurrentStatus}
                updateFilterCurrentStatus={updateFilterCurrentStatus} 
                allOrders={allOrders} />
            }
            <div>
                <div className={styles.topWrapper}>
                    <div className={styles.statsTxt}>
                        All Delivery Locations for Order #{orderIdentifier}
                    </div>
                    <div className={styles.searchInput}>
                        <input
                            placeholder="Search for anything..."
                            value={searchKey}
                            onChange={(e) => setSearchKey(e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.filtersWrapper}>
                    <div className={styles.filterWrapper} >
                        <button
                            className={filterCurrentStatus.length === 0 ? styles.activeStatus : styles.inactiveStatus}
                            onClick={() => updateFilterCurrentStatus('All')}
                        >
                            All
                        </button>
                        {['booked', 'pending pickup', 'in transit', 'delivered', 'out for delivery', 'exception', 'rto'].map(status => (
                            <button
                                key={status}
                                className={filterCurrentStatus.includes(status) ? styles.activeStatus : styles.inactiveStatus}
                                onClick={() => updateFilterCurrentStatus(status)}
                            >
                                {status.charAt(0).toUpperCase() + status.slice(1)} ({allOrders.filter(item => item.currentStatus === status).length})
                            </button>
                        ))}
                    </div>
                    <div className={styles.filterWrapperMob}>
                        <button onClick={() => setIsFilterOpen(true)}>
                            <FaFilter /> Show Filters
                        </button>
                    </div>

                    <div className={styles.downloadBtn}>
                        <button onClick={() => downloadCSV([`clientIdentifier`, `orderIdentifier`, `awb`, `currentStatus`, `lastStatusUpdatedOn`, `fullname`, `phoneNumber`, `address`, `city`, `state`, `pincode`])}>
                            Download CSV
                        </button>
                    </div>
                </div>

                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>#</div>
                        <div className={styles.fullname}>Full Name</div>
                        <div className={styles.phoneNumber}>Phone</div>
                        <div className={styles.address}>Address</div>
                        <div className={styles.status}>Current Status</div>
                        <div className={styles.time}>Track</div>
                    </div>
                    <div className={styles.rowsWrapper}>
                        {filteredData.map((item, index) => (
                            <div className={styles.eachRowWrapper} key={index}>
                                <div className={styles.sn}>{index + 1}.</div>
                                <div className={styles.fullname}>{item.fullname}</div>
                                <div className={styles.phoneNumber}>{item.phoneNumber}</div>
                                <div className={styles.address}>{item.address}</div>
                                <div className={styles.status}>
                                    <div className={styles.currentStatus}>{item.currentStatus}</div>
                                </div>
                                <div className={styles.time}>

                                    <div className={styles.status}>
                                        <div className={styles.currentStatus}>{item.currentStatus}</div>
                                    </div>

                                    <a href={`/track/order/${item.awb}`} target="_blank" rel="noopener noreferrer">Track Status</a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllOrders;
