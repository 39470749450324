import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './MainHome.module.css'

const MainHome = () => {

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ECFAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ECFAFF";
            document.head.appendChild(meta);
        }
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <Link to={`/orders`} className={styles.eachCardWrapper}>
                <div >
                    Track Order
                </div>
            </Link>
        </div>
    )
}

export default MainHome