import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './OrderSummary.module.css'
import { useParams } from 'react-router'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { BASE_URL_API } from '../../References/url'
import AuthContext from '../../Store/auth-context'

const OrderSummary = () => {
    const { orderIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [orderSummary, setOrderSummary] = useState(null)

    const [data, setData] = useState([
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
        { name: 'Group D', value: 200 },
    ])

    const [data01, setData01] = useState([
        {
            "name": "Completed",
            "value": 82
        },
        {
            "name": "Remaining",
            "value": 18
        },
    ])

    const COLORS = ['#92c43d', '#dfdfdf'];

    const getOrderSummary = async () => {
        const getOrderSummaryResponse = await fetch(BASE_URL_API + "/getOrderSummary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    orderIdentifier: orderIdentifier
                })
            });

        if (!getOrderSummaryResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getOrderSummaryRespo = await getOrderSummaryResponse.json()
            if (getOrderSummaryRespo.status === "success") {
                setOrderSummary(getOrderSummaryRespo?.response)
            } else {
                if (getOrderSummaryRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setOrderSummary(null)
                }
            }
            console.log(getOrderSummaryRespo)
        }
    }

    useEffect(() => {
        getOrderSummary()
    }, [orderIdentifier])

    useEffect(() => {
        if (orderSummary && orderSummary.length > 0) {
            // Find the percentage for the status "delivered"
            const deliveredData = orderSummary.find(item => item.currentStatus === "delivered");

            if (deliveredData) {
                const completedPercentage = parseFloat(deliveredData.percentage);
                const remainingPercentage = 100 - completedPercentage;

                setData01([
                    { "name": "Completed", "value": completedPercentage },
                    { "name": "Remaining", "value": remainingPercentage }
                ]);
            }
        }
    }, [orderSummary]);

    const COLORSNEW = ['red', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    useEffect(() => {
        if (orderSummary && orderSummary?.length > 0) {
            // Map orderSummary data to the format required for data
            const updatedData = orderSummary.map(item => ({
                name: item.currentStatus,
                value: Number(item.volume),
            }));

            setData(updatedData);
        }
    }, [orderSummary]);

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ECFAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ECFAFF";
            document.head.appendChild(meta);
        }
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.osHeader}>
                <div className={styles.osTxt}>
                    Order Summary
                </div>
                <div className={styles.trackOrderBtn}>
                    <Link to={`/order/${orderIdentifier}/track`}>Track Every Order</Link>
                </div>
            </div>

            <div className={styles.statsWrapper}>
                <div className={styles.card}>
                    <div className={styles.cardTitle}>Total Locations</div>
                    <div className={styles.cardValue}>{orderSummary?.reduce((sum, item) => sum + parseInt(item?.volume, 10), 0)}</div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardTitle}>Delivered Ordered</div>
                    <div className={styles.cardValue}>{orderSummary?.find((item) => item?.currentStatus === "delivered")?.volume}</div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardTitle}>Order value</div>
                    <div className={styles.cardValue}>₹412000</div>
                </div>
                <div className={styles.cardGraph}>
                    <div className={styles.graphWrapper}>
                        <ResponsiveContainer width={"100%"} height="100%">
                            <PieChart width={200} height={160}>
                                <Pie data={data01} dataKey="value"
                                    nameKey="name"
                                    // cx="50%" cy="50%" 
                                    innerRadius={80}
                                    outerRadius={120}
                                    fill="#92C43D"
                                    startAngle={180}
                                    endAngle={0}
                                >
                                    {data01.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className={styles.cardGraphTxt}>
                        <div className={styles.cardTitlePercentage}>
                            {parseFloat(orderSummary?.find((item) => item?.currentStatus === "delivered")?.percentage).toFixed(2)}%
                        </div>
                        <div className={styles.cartTitleTxt} >
                            order completed
                        </div>
                    </div>

                </div>


                <div className={styles.cardPie}>

                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORSNEW[index]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend 
                                wrapperStyle={{ backgroundColor : '#dfdfdf45', fontFamily : 'Montserrat', fontSize : '0.8rem', fontWeight: '600', padding : '6px 0px', borderRadius : '6px', margin : '0px 2% 5px', width : '96%'}} 
                            />
                        </PieChart>
                    </ResponsiveContainer>

                </div>

            </div>


        </div>
    )
}

export default OrderSummary