import React, { useContext, useEffect, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import EachProduct from '../../Components/Catalogue/EachProduct'
import { BASE_URL_API } from '../../References/url'
import AuthContext from '../../Store/auth-context'
import styles from './AllProducts.module.css'

const AllProducts = () => {
    const authCtx = useContext(AuthContext)
    const [allItems, setAllItems] = useState([])
    const [wishlist, setWishlist] = useState([])
    const toggleWishlistItem = (k) => {
        console.log(k, wishlist)
        if (wishlist.includes(k)) {
            setWishlist(wishlist.filter((item) => item !== k))
        } else {
            setWishlist([...wishlist, k])
        }

    }

    const getAllEcomProducts = async () => {
        const getAllEcomProductsResponse = await fetch(BASE_URL_API + "/getAllEcomProducts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllEcomProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllEcomProductsRespo = await getAllEcomProductsResponse.json()
            if (getAllEcomProductsRespo.status === "success") {
                setAllItems(getAllEcomProductsRespo?.response)
            } else {
                if (getAllEcomProductsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllItems([])
                }
            }
        }
    }


    useEffect(() => {
        getAllEcomProducts()
    }, [])

    // console.log(allItems)
    console.log("this is wishlist", wishlist);
    console.log(allItems?.some(item => wishlist.includes(item.id)));

    return (
        <div>
            <div className={styles.topWrapper} >
                <div className={styles.productTitle}>
                    All Products ({allItems?.length})
                </div>
                <div className={styles.righttw}>
                    <Link to={`/wishlist`} className={styles.wishlist}>
                        Wishlist ({wishlist?.length})
                    </Link>
                    <div className={styles.filterIcon}>
                        <button><FaFilter /></button>
                    </div>
                </div>
            </div>
            <div className={styles.productsWrapper}>
                <div className={styles.productsContainer} >
                    {allItems?.map((item, index) => (
                        <EachProduct 
                            item={item} key={index} 
                            inWishlist={wishlist?.includes(item?.id)} 
                            toggleWishlistItem={toggleWishlistItem}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AllProducts