import React from 'react'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import styles from './EachProduct.module.css'

const EachProduct = (props) => {
    const { item, inWishlist, toggleWishlistItem } = props

    return (
        <div className={styles.eachItemWrapper}>
            <div className={styles.thumbnail} >
                <img src={item?.thumbnail} />
                {inWishlist===true  ?
                    <div className={styles.wishlistIconAdded} onClick={() => toggleWishlistItem(item?.id)}>
                        <FaHeart size={22} color="#9524A3" />
                    </div>
                    :
                    <div className={styles.wishlistIcon}>
                        <FaRegHeart size={22} color="#dfdfdf"  onClick={() => toggleWishlistItem(item?.id)} />
                    </div>
                }
            </div>
            <div className={styles.title}>
                {item?.title}
            </div>
            <div className={styles.brand}>
                {item?.brand}
            </div>
            <div className={styles.categoryWrapper}>
                <div className={styles.category}>
                    {item?.category}
                </div>
            </div>
        </div>
    )
}

export default EachProduct