import React, { useContext, useEffect, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { BASE_URL_API } from '../../References/url'
import AuthContext from '../../Store/auth-context'
import styles from './UserData.module.css'

const UserData = () => {
    const authCtx = useContext(AuthContext)
    const [userData, setUserData] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [filteredArray, setFilteredArray] = useState([])

    const getAllUsers = async () => {
        const getAllUsersResponse = await fetch(BASE_URL_API + "/getAllUsers",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientIdentifier: authCtx.clientIdentifier,
                    username: authCtx.username
                })
            });

        if (!getAllUsersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllUsersRespo = await getAllUsersResponse.json()
            if (getAllUsersRespo.status === "success") {
                setUserData(getAllUsersRespo.response)
                setFilteredArray(getAllUsersRespo.response)
            } else {
                if (getAllUsersRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAllUsersRespo)

        }
    }


    useEffect(() => {
        getAllUsers()
    }, [])

    const searchArray = () => {
        // Handle empty, blank, or whitespace-only searchTerm
        if (!searchKey || searchKey.trim() === "") {
            setFilteredArray(userData); // Return all results
        }

        // Filter the array to include only objects where any of their properties' values (converted to lowercase) include the search term
        const filteredArray = userData.filter((object) => {
            for (const key in object) {
                if (object.hasOwnProperty(key) && object[key].toLowerCase().includes(searchKey.toLowerCase())) {
                    return true; // Stop iterating if a match is found
                }
            }
            return false; // No match found in any property
        });

        setFilteredArray(filteredArray);
    }


    useEffect(() => {
        searchArray()
    }, [searchKey])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div>
                    <div className={styles.userdataTag}>
                        <div className={styles.userdataTxt}>
                            User Data
                        </div>
                    </div>
                    <div className={styles.searchWrapper}>
                        <div className={styles.searchContainer}>
                            <div className={styles.searchBoxWrapper}>
                                <input placeholder="Search for your users" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                            </div>
                            <div className={styles.filterBoxWrapper}>
                                <div className={styles.filterIcon}>
                                    <FaFilter />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={styles.tableWrapper}>
                        <div className={styles.tableHeader}>
                            <div className={styles.sn}>
                                #
                            </div>
                            <div className={styles.fullName}>
                                Full Name
                            </div>
                            <div className={styles.phoneNumber}>
                                Phone Number
                            </div>
                            <div className={styles.addedOn}>
                                Added On
                            </div>
                            <div className={styles.action}>
                                Address
                            </div>
                        </div>
                        <div className={styles.tableBodyWrapper}>
                            {filteredArray?.map((item, index) => (
                                <div key={index} className={styles.eachRecordWrapper}>
                                    <div className={styles.sn}>
                                        {index + 1}.
                                    </div>
                                    <div className={styles.fullName}>
                                        {item?.firstName} {item?.middleName} {item?.lastName}
                                    </div>
                                    <div className={styles.phoneNumber}>
                                        {item?.phoneNumber}
                                    </div>
                                    <div className={styles.addedOn}>
                                        {item?.createdOn?.substring(0, 10)}
                                    </div>
                                    <div className={styles.action}>
                                        {item?.gender}
                                    </div>

                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserData