import React, { useContext, useEffect, useState } from 'react'
import { listOfStates } from '../../Helpers/listOfStates.js'
import { BASE_URL_API } from '../../References/url.js'
import AuthContext from '../../Store/auth-context.js'
import styles from './GSTDetails.module.css'

const GSTDetails = () => {
    const authCtx = useContext(AuthContext)
    const [isAddingNewGst, setIsAddingNewGst] = useState(false)

    const [state, setState] = useState('')
    const [gstNumber, setGstNumber] = useState('')
    const [address, setAddress] = useState('')
    const [isCreating, setIsCreating] = useState(false)

    const [gstRecords, setGstRecords] = useState([])

    const getGstDetails = async () => {
        console.log(authCtx)
        const getGstDetailsResponse = await fetch(BASE_URL_API + "/getGstDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientIdentifier: authCtx?.clientIdentifier
                })
            });

        if (!getGstDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getGstDetailsRespo = await getGstDetailsResponse.json();
            if (getGstDetailsRespo.status === "success") {
                setGstRecords(getGstDetailsRespo.response)
            } else {
                if (getGstDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getGstDetailsRespo)
        }
    }

    const addNewGST = async () => {
        if (gstNumber !== '') {
            if (state !== "Select") {
                if (address !== "") {
                    setIsCreating(true)
                    const addNewGSTResponse = await fetch(BASE_URL_API + "/addNewGST",
                        {
                            method: "POST",
                            body: JSON.stringify({
                                token: authCtx.token,
                                clientIdentifier: authCtx?.clientIdentifier,
                                username: authCtx?.username,
                                state: state,
                                gstNumber: gstNumber,
                                address: address
                            })
                        });

                    if (!addNewGSTResponse.ok) {
                        console.log("Something went wrong : Server Error")
                    } else {
                        const addNewGSTRespo = await addNewGSTResponse.json()
                        if (addNewGSTRespo.status === "success") {
                            setIsAddingNewGst(false)
                            getGstDetails()
                        } else {
                            if (addNewGSTRespo.message === "token Expired, please login again!") {
                                authCtx.logout()
                            } else {
                                alert(addNewGSTRespo?.message)
                            }
                        }
                        console.log(addNewGSTRespo)
                    }
                    setIsCreating(false)

                } else {
                    alert("Please enter a valid address")
                }
            } else {
                alert("Please select a state of business")
            }
        } else {
            alert("Please enter a valid GST Number")
        }

    }

    useEffect(() => {
        getGstDetails()
    }, [])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.upperRowWrapper}>
                        <div className={styles.upperRowContainer}>
                            <div className={styles.urHeaderRow}>
                                <div className={styles.eachRowTitle}>
                                    Gst Details
                                </div>
                                <div className={styles.addNewBtn}>
                                    <button onClick={() => setIsAddingNewGst(!isAddingNewGst)}>{isAddingNewGst === true ? `Cancel` : `Add New GST`}</button>
                                </div>

                            </div>
                            {isAddingNewGst &&
                                <div className={styles.urContentWrapper}>
                                    <div className={styles.createNewGstWrapper}>
                                        <div className={styles.twoInRow}>

                                            <div className={styles.eachFieldWrapper}>
                                                <div className={styles.eachFieldTitle}>
                                                    Please select state
                                                </div>
                                                <div className={styles.eachFieldInput}>
                                                    <select onChange={(e) => setState(e.target.value)}>
                                                        <option>Select</option>
                                                        {listOfStates?.map((item, index) => (
                                                            <option key={index} value={item?.name}>{item?.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>

                                            <div className={styles.eachFieldWrapper}>
                                                <div className={styles.eachFieldTitle}>
                                                    Please enter gst number
                                                </div>
                                                <div className={styles.eachFieldInput}>
                                                    <input autoFocus className={styles.gstNumber}
                                                        placeholder="Please enter gst number..."
                                                        onChange={(e) => setGstNumber(e.target.value)}
                                                        value={gstNumber}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className={styles.eachFieldWrapper}>
                                            <div className={styles.eachFieldTitle}>
                                                Please enter registered address
                                            </div>
                                            <div className={styles.eachFieldInput}>
                                                <textarea placeholder="Please enter registered address..."
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.addNowBtn}>
                                            {isCreating === true ?
                                                <button>Creating...</button>
                                                :
                                                <button onClick={() => addNewGST()} >Add</button>
                                            }
                                        </div>



                                    </div>

                                </div>
                            }
                        </div>

                    </div>
                    <div className={styles.lowerRowWrapper}>

                        <div className={styles.lowerRowContainer}>
                            <div className={styles.lrContentWrapper}>
                                {gstRecords?.map((item, index) => (
                                    <div className={styles.eachGstCardWrapper} key={index}>
                                        <div className={styles.tagWrapper}>
                                            {item?.isPrimary === '1' && <div className={styles.tag}>Primary</div>}
                                        </div>
                                        <div className={styles.numberWrapper}>
                                            {item?.gstNumber}
                                        </div>
                                        <div className={styles.addressWrapper}>
                                            {item?.address}
                                        </div>

                                    </div>
                                ))}

                            </div>

                        </div>


                    </div>

                </div>

            </div>
        </div>
    )
}

export default GSTDetails