import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/url'
import AuthContext from '../../Store/auth-context'
import styles from './OrdersMaster.module.css'

const OrdersMaster = () => {

    const [allOrders, setAllOrders] = useState([])
    const authCtx = useContext(AuthContext)
    const [filteredData, setFilteredData] = useState([])
    const [searchKey, setSearchKey] = useState('')

    const getAllOrder = async () => {
        const getAllOrderResponse = await fetch(BASE_URL_API + "/getAllOrder",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    clientIdentifier: authCtx.clientIdentifier
                })
            });

        if (!getAllOrderResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllOrderRespo = await getAllOrderResponse.json()
            if (getAllOrderRespo.status === "success") {
                setAllOrders(getAllOrderRespo?.response)
                setFilteredData(getAllOrderRespo?.response)
            } else {
                if (getAllOrderRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllOrders([])
                }
            }
            console.log(getAllOrderRespo)
        }
    }

    useEffect(() => {
        getAllOrder()
    }, [])




    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterData(allOrders, searchKey)
    }, [searchKey])

    return (
        <div>
            <div className={styles.mainWrapper}>
                <div>
                    <div className={styles.topWrapper}>
                        <div className={styles.statsTxt}>
                            All Orders
                        </div>
                        <div className={styles.searchInput}>
                            <input placeholder="Search for anything..." value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                        </div>
                    </div>

                    <div className={styles.tableWrapper}>
                        <div className={styles.tableHeader}>
                            <div className={styles.sn}>#</div>
                            <div className={styles.qr}>Title</div>
                            <div className={styles.item}>Description</div>
                            <div className={styles.itemQuantity}>Quantity</div>
                            <div className={styles.points}>Amount</div>
                            <div className={styles.placeOfSupply}>Place(s) of Supply</div>
                            <div className={styles.time}>Track</div>
                        </div>
                        <div className={styles.rowsWrapper}>
                            {filteredData?.map((item, index) => (
                                <div className={styles.eachRowWrapper} key={index}>
                                    <div className={styles.sn}>{index + 1}.</div>
                                    <div className={styles.fullname}>{item?.orderTitle}</div>
                                    <div className={styles.phoneNumber}>{item?.phoneNumber}</div>
                                    <div className={styles.itemQuantity}>
                                        <div className={styles.posTitle}>
                                            Quantity :
                                        </div>
                                        {item?.itemQuantity}
                                    </div>
                                    <div className={styles.address}>{item?.address}</div>
                                    <div className={styles.placeOfSupply}>
                                        <div className={styles.posTitle}>
                                            Place of Supply :
                                        </div>
                                        {item?.placeOfSupply}
                                    </div>
                                    <div className={styles.time}>
                                        <Link to={`/order/${item?.orderIdentifier}/summary`}>View Details</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default OrdersMaster