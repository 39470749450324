import React, { useEffect, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { useParams } from 'react-router'
import { humanReadableTimePassed } from '../../Helper/Algos'
import { BASE_URL_API } from '../../References/url'
import styles from './TrackOrder.module.css'

const TrackOrder = () => {
    const { awb } = useParams()
    const [orderData, setOrderData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const getEbOrderStatus = async () => {
        setIsLoading(true)
        const getEbOrderStatusResponse = await fetch(BASE_URL_API + "/getEbOrderStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    awb: awb
                })
            });

        if (!getEbOrderStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEbOrderStatusRespo = await getEbOrderStatusResponse.json()
            if (getEbOrderStatusRespo.status === "success") {
                setOrderData(getEbOrderStatusRespo?.response?.data)
            } else {
                setOrderData(null)
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getEbOrderStatus()
    }, [awb])


    const updateOrderStatus = async () => {
        const updateOrderStatusResponse = await fetch(BASE_URL_API+"/updateOrderStatus",
        {
            method : "POST",
            body : JSON.stringify({
                awb : awb,
                status : orderData?.status
            })
        });

        if(!updateOrderStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateOrderStatusRespo = await updateOrderStatusResponse.json()
            if(updateOrderStatusRespo.status==="success") {
                
            } else {

            }
            console.log(updateOrderStatusRespo)
        }
    }

    useEffect(() => {
        if(orderData?.status!=='') {
            updateOrderStatus()
        }
    }, [orderData])
    


    if (isLoading) {
        return (
            <div className={styles.loadingWrapper}>
                <div className={styles.loadingTxtWrapper}>
                    <div className={styles.pw}>
                        Please wait..
                    </div>
                    <div className={styles.cyd}>
                        Collecting your order details!
                    </div>
                </div>
                <div className={styles.loadingContainer}>
                    <img className={styles.webIcon} src="https://rajairexpress.com/img/scooter-courier.gif" />
                    <img className={styles.mobIcon} src="https://cdn.dribbble.com/users/889322/screenshots/7792483/media/92dc9f2741129a8af0133c77e51c9699.gif" />
                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.pageTitle} >
                    <div>
                        <h2>
                            Track Order : {awb}
                        </h2>
                    </div>
                    <div className={styles.currentStatusWrapper}>
                        <div className={styles.currentStatus}>{orderData?.status}</div>
                    </div>
                </div>
                <div className={styles.trackWrapperWeb}>
                    {orderData?.history?.slice().reverse().map((item, index) => (
                        <div className={styles.eachStepWrapper} key={index}>
                            <div className={styles.eventTimeTxtWrapper}>
                                <div className={styles.message}>
                                    {item?.message}
                                </div>
                                <div className={styles.eventTime}>
                                    {humanReadableTimePassed(item?.event_time)}
                                </div>
                            </div>
                            <div className={styles.connectorWrapper}>
                                <div className={index === 0 ? styles.connectingLineTrans : styles.connectingLine}></div>
                                <div className={styles.centerDot}><FaCheck size={20} /></div>
                                {/* <div className={index === orderData?.history?.length - 1 ? styles.connectingLineTrans : styles.connectingLine}></div> */}
                                <div className={styles.connectingLine}></div>
                            </div>
                            <div className={styles.bottomMessage}>
                                <div className={styles.eventTimeTxtWrapperMob}>
                                    <div className={styles.message}>
                                        {item?.message}
                                    </div>
                                    <div className={styles.eventTime}>
                                        {humanReadableTimePassed(item?.event_time)}
                                    </div>
                                </div>
                                <div className={styles.location}>
                                    {item?.location}
                                </div>
                            </div>
                        </div>
                    ))}

                    {orderData?.history?.every(item => item.status_code !== "IT") &&
                        <div className={styles.eachStepWrapper}>
                            <div className={styles.eventTimeTxtWrapper}>
                                <div className={styles.message}>
                                    In-Transit
                                </div>
                                <div className={styles.eventTime}>
                                    Soon
                                </div>
                            </div>
                            <div className={styles.connectorWrapper}>
                                <div className={styles.connectingLineDull}></div>
                                <div className={styles.centerDotDull}></div>
                                <div className={styles.connectingLineDull}></div>
                            </div>
                            <div className={styles.bottomMessage}>
                                <div className={styles.eventTimeTxtWrapperMob}>
                                    <div className={styles.message}>
                                        In Transit
                                    </div>
                                    <div className={styles.eventTime}>
                                        within some time
                                    </div>
                                </div>
                                <div className={styles.location}>
                                    On the way
                                </div>
                            </div>
                        </div>
                    }
                    {orderData?.history?.every(item => item.status_code !== "DL") &&
                        <div className={styles.eachStepWrapper}>
                            <div className={styles.eventTimeTxtWrapper}>
                                <div className={styles.message}>
                                    Delivered
                                </div>
                                <div className={styles.eventTime}>
                                    Soon
                                </div>
                            </div>
                            <div className={styles.connectorWrapper}>
                                <div className={styles.connectingLineDull}></div>
                                <div className={styles.centerDotDull}></div>
                                <div className={styles.connectingLineTrans}></div>
                            </div>
                            <div className={styles.bottomMessage}>
                                <div className={styles.eventTimeTxtWrapperMob}>
                                    <div className={styles.message}>
                                        Expected to deliver soon!
                                    </div>
                                    <div className={styles.eventTime}>
                                        within few days
                                    </div>
                                </div>
                                <div className={styles.location}>
                                    At your place
                                </div>
                            </div>
                        </div>
                    }
                </div>


                <div className={styles.trackWrapperMob}>
                    {orderData?.history?.every(item => item.status_code !== "DL") &&
                        <div className={styles.eachStepWrapper}>
                            <div className={styles.eventTimeTxtWrapper}>
                                <div className={styles.message}>
                                    Delivered
                                </div>
                                <div className={styles.eventTime}>
                                    Soon
                                </div>
                            </div>
                            <div className={styles.connectorWrapper}>
                                <div className={styles.connectingLineTrans}></div>
                                <div className={styles.centerDotDull}></div>
                                <div className={styles.connectingLineDull}></div>
                            </div>
                            <div className={styles.bottomMessage}>
                                <div className={styles.eventTimeTxtWrapperMob}>
                                    <div className={styles.message}>
                                        Expected to deliver soon!
                                    </div>
                                    <div className={styles.eventTime}>
                                        within few days
                                    </div>
                                </div>
                                <div className={styles.location}>
                                    At your place
                                </div>
                            </div>
                        </div>
                    }
                    {orderData?.history?.every(item => item.status_code !== "IT") &&
                        <div className={styles.eachStepWrapper}>
                            <div className={styles.eventTimeTxtWrapper}>
                                <div className={styles.message}>
                                    In-Transit
                                </div>
                                <div className={styles.eventTime}>
                                    Soon
                                </div>
                            </div>
                            <div className={styles.connectorWrapper}>
                                <div className={styles.connectingLineDull}></div>
                                <div className={styles.centerDotDull}></div>
                                <div className={styles.connectingLineDull}></div>
                            </div>
                            <div className={styles.bottomMessage}>
                                <div className={styles.eventTimeTxtWrapperMob}>
                                    <div className={styles.message}>
                                        In Transit
                                    </div>
                                    <div className={styles.eventTime}>
                                        within some days
                                    </div>
                                </div>
                                <div className={styles.location}>
                                    On the way
                                </div>
                            </div>
                        </div>
                    }
                    {orderData?.history?.map((item, index) => (
                        <div className={styles.eachStepWrapper} key={index}>
                            <div className={styles.eventTimeTxtWrapper}>
                                <div className={styles.message}>
                                    {item?.message}
                                </div>
                                <div className={styles.eventTime}>
                                    {humanReadableTimePassed(item?.event_time)}
                                </div>
                            </div>
                            <div className={styles.connectorWrapper}>
                                <div className={styles.connectingLine}></div>
                                <div className={styles.centerDot}><FaCheck size={20} /></div>
                                <div className={index === orderData?.history?.length - 1 ? styles.connectingLineTrans : styles.connectingLine}></div>

                            </div>
                            <div className={styles.bottomMessage}>
                                <div className={styles.eventTimeTxtWrapperMob}>
                                    <div className={styles.message}>
                                        {item?.message}
                                    </div>
                                    <div className={styles.eventTime}>
                                        {humanReadableTimePassed(item?.event_time)}
                                    </div>
                                </div>
                                <div className={styles.location}>
                                    {item?.location}
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className={styles.thatsAll}>
                        That's it!
                    </div>

                </div>

                <div className={styles.footer}>
                    For enquiries&nbsp;<a href="https://qnnox.com"> Visit Qnnox</a>
                </div>

            </div>
        </div>
    )
}

export default TrackOrder