import React, { useContext } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import MainFooter from '../Components/Footers/MainFooter';
import LeftHeader from '../Components/Headers/LeftHeader';
import MainHeader from '../Components/Headers/MainHeader';
import AnalysisHome from '../Pages/Analysis/AnalysisHome';
import Login from '../Pages/Authentications/Login';
import Invoices from '../Pages/Billings/Invoices';
import AllProducts from '../Pages/Catalogue/AllProducts';
import Wishlist from '../Pages/Ecom/Wishlist';
import MainHome from '../Pages/Home/MainHome';
import GettingStarted from '../Pages/Informatives/GettingStarted';
import AllOrders from '../Pages/Orders/AllOrders';
import TrackOrder from '../Pages/Orders/TrackOrder';
import GSTDetails from '../Pages/User/GSTDetails';
import UserData from '../Pages/User/UserData';
import AuthContext from '../Store/auth-context';
import styles from './MainNavigator.module.css';
import "react-toastify/dist/ReactToastify.css";
import OrdersMaster from '../Pages/Orders/OrdersMaster';
import OrderSummary from '../Pages/Orders/OrderSummary';


const MainNavigator = () => {
    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx?.isLoggedIn;
    const location = useLocation();

    // Check if current path is the TrackOrder page
    const isTrackOrderPath = location.pathname.startsWith('/track/order/');

    if (!isLoggedIn) {
        return (
            <div>
                <ToastContainer />

                <Routes>
                    <Route path="*" element={<Login />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/track/order/:awb" element={<TrackOrder />} />
                </Routes>
            </div>
        );
    }

    return (
        <div>
            <ToastContainer />
            <Routes>
                <Route path="/track/order/:awb" element={<TrackOrder />} />
            </Routes>

            {!isTrackOrderPath && (
                <div className={styles.mainWrapper}>
                    <MainHeader />
                    <div className={styles.mainBodyWrapper}>
                        <div className={styles.mainLeftWrapper}>
                            <LeftHeader />
                        </div>
                        <div className={styles.mainRightWrapper}>
                            <Routes>
                                <Route path="*" element={<MainHome />} />
                                <Route path="/" element={<GettingStarted />} />
                                <Route path="/home" element={<MainHome />} />
                                <Route path="/user-data" element={<UserData />} />
                                <Route path="/getting-started" element={<GettingStarted />} />
                                <Route path="/analysis" element={<AnalysisHome />} />
                                <Route path="/gst-details" element={<GSTDetails />} />
                                <Route path="/invoice" element={<Invoices />} />
                                <Route path="/catalogue" element={<AllProducts />} />
                                <Route path="/wishlist" element={<Wishlist />} />
                                <Route path="/orders" element={<OrdersMaster />} />
                                <Route path="/order/:orderIdentifier/track" element={<AllOrders />} />
                                <Route path="/order/:orderIdentifier/summary" element={<OrderSummary />} />
                            </Routes>
                        </div>
                    </div>
                    <MainFooter />
                </div>
            )}
        </div>
    );
};

export default MainNavigator;
