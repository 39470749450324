import React, { useState } from 'react'
import styles from './AnalysisHome.module.css'

const AnalysisHome = () => {

    const [recentTransactions, setRecentTransactions] = useState([1, 2, 3,  4, 5, 6, 7, 8, 9, 10, 11, 12, 13])

    return (
        <div className={styles.mainWrapper}>

            <div className={styles.mainHeaderWrapper}>
                <div>
                    <div className={styles.bigTxt}>
                        Analysis
                    </div>
                    <div className={styles.smallTxt}>
                        Understand how your users are responding to your programs
                    </div>
                </div>
                <div>
                    <select>
                        <option>This Week</option>
                        <option>Last Week</option>
                    </select>
                </div>
            </div>

            <div>
                <div className={styles.statsTxt}>
                    Stats
                </div>
                <div className={styles.tilesWrapper}>

                    <div className={styles.eachCardWrapper}>
                        <div className={styles.eachCardContainer}>
                            <div className={styles.cardHeader}>
                                <div className={styles.cardTitle}>
                                    Total Sales
                                </div>
                                <div className={styles.cardIcon}>
                                    ₹
                                </div>
                            </div>
                            <div className={styles.cardBody}>
                                <div className={styles.valueTxt}>
                                    -
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.eachCardWrapper}>
                        <div className={styles.eachCardContainer}>
                            <div className={styles.cardHeader}>
                                <div className={styles.cardTitle}>
                                    Total Points Accumulated
                                </div>
                                <div className={styles.cardIcon}>
                                    ₹
                                </div>
                            </div>
                            <div className={styles.cardBody}>
                                <div className={styles.valueTxt}>
                                    -
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.eachCardWrapper}>
                        <div className={styles.eachCardContainer}>
                            <div className={styles.cardHeader}>
                                <div className={styles.cardTitle}>
                                    Total Points Redeemed
                                </div>
                                <div className={styles.cardIcon}>
                                    ₹
                                </div>
                            </div>
                            <div className={styles.cardBody}>
                                <div className={styles.valueTxt}>
                                    -
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachCardWrapper}>
                        <div className={styles.eachCardContainer}>
                            <div className={styles.cardHeader}>
                                <div className={styles.cardTitle}>
                                    Total Delivered
                                </div>
                                <div className={styles.cardIcon}>
                                    ₹
                                </div>
                            </div>
                            <div className={styles.cardBody}>
                                <div className={styles.valueTxt}>
                                    -
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div>
                <div className={styles.statsTxt}>
                    Recent Transactions
                </div>

                {/* <div>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>#</div>
                        <div className={styles.qr}>Qr</div>
                        <div className={styles.item}>Item</div>
                        <div className={styles.points}>Points</div>
                        <div className={styles.username}>Username</div>
                        <div className={styles.time}>Time</div>
                    </div>
                    <div className={styles.rowsWrapper}>
                        {recentTransactions?.map((item, index) => (
                            <div className={styles.eachRowWrapper} key={index}>
                                <div className={styles.sn}>{index+1}.</div>
                                <div className={styles.qr}>4wes5</div>
                                <div className={styles.item}>25 bags</div>
                                <div className={styles.points}>999</div>
                                <div className={styles.username}>Navneet</div>
                                <div className={styles.time}>10 seconds ago</div>
                            </div>
                        ))}

                    </div>
                </div> */}


            </div>


        </div>
    )
}

export default AnalysisHome