import React from 'react'
import styles from './Invoices.module.css'

const Invoices = () => {
    return (
        <div className={styles.mainWrapper}>
            <div>
                Your invoices will appear here!
            </div>
        </div>
    )
}

export default Invoices