import React from 'react'
import styles from './LeftHeader.module.css'
import { FaCartPlus, FaPlay, FaUser } from "react-icons/fa";
import { IoAnalyticsOutline } from "react-icons/io5";
import { FcCustomerSupport } from "react-icons/fc";
import { RiCustomerService2Fill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { PiNavigationArrow } from "react-icons/pi";





const LeftHeader = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sectionWrapper}>
                <div className={styles.sectionTitle}>Dashboard</div>
                <div>
                    <Link to="/getting-started" className={styles.eachNavWrapper}>
                        <div className={styles.eachNavIcon}>
                            <FaPlay />
                        </div>
                        <div>
                            Getting Started
                        </div>
                    </Link>

                    <Link to="/analysis" className={styles.eachNavWrapper}>
                        <div>
                            <IoAnalyticsOutline />
                        </div>
                        <div>
                            Analysis
                        </div>
                    </Link>

                    <Link to="user-data" className={styles.eachNavWrapper}>
                        <div>
                            <FaUser />
                        </div>
                        <div>
                            User Data
                        </div>
                    </Link>
                </div>

            </div>

            <div>
                <div className={styles.sectionTitle}>Services</div>
                <div>
                    <Link to={`/catalogue`} className={styles.eachNavWrapper}>
                        <div>
                            <FaCartPlus />
                        </div>
                        <div>
                            Catalogue
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <div className={styles.sectionTitle}>Orders</div>
                <div>
                    <Link to={`/orders`} className={styles.eachNavWrapper}>
                        <div>
                            <PiNavigationArrow />
                        </div>
                        <div>
                            Track Order
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <div className={styles.sectionTitle}>Account</div>
                <div>
                    <Link to="invoice" className={styles.eachNavWrapper}>
                        <div>
                            <FaUser />
                        </div>
                        <div>
                            Invoice
                        </div>
                    </Link>

                    <Link to="gst-details" className={styles.eachNavWrapper}>
                        <div>
                            <FaUser />
                        </div>
                        <div>
                            GST Details
                        </div>
                    </Link>
                </div>

            </div>


            <div>
                <div className={styles.sectionTitle}>Support</div>
                <div>
                    <div className={styles.eachNavWrapper}>
                        <div className={styles.eachNavIcon}>
                            <RiCustomerService2Fill size={20} />
                        </div>
                        <div>
                            Customer Support
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LeftHeader