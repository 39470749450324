import React from 'react'
import styles from './Wishlist.module.css'

const Wishlist = () => {
    return (
        <div>
            Wishlist

        </div>
    )
}

export default Wishlist