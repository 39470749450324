import React from 'react'
import styles from './FiltersAndActions.module.css'

const FiltersAndActions = (props) => {
    const { isOpen, onClose, allOrders, filterCurrentStatus, updateFilterCurrentStatus } = props
    window.onclick = event => event.target.id === "otfanw" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="otfanw">
                    <div className={styles.mainContent}>
                        <div className={styles.titleWrapper}>
                            Filters
                        </div>


                        <div className={styles.filterWrapper} >
                            <button
                                className={filterCurrentStatus?.length === 0 ? styles.activeStatus : styles.inactiveStatus}
                                onClick={() => updateFilterCurrentStatus('All')}
                            >
                                All
                            </button>
                            {['booked', 'pending pickup', 'in transit', 'delivered', 'out for delivery', 'exception', 'rto'].map(status => (
                                <button
                                    key={status}
                                    className={filterCurrentStatus?.includes(status) ? styles.activeStatus : styles.inactiveStatus}
                                    onClick={() => updateFilterCurrentStatus(status)}
                                >
                                    {status?.charAt(0)?.toUpperCase() + status?.slice(1)} ({allOrders?.filter(item => item?.currentStatus === status)?.length})
                                </button>
                            ))}
                        </div>

                        <div className={styles.close}>
                            <button onClick={() => onClose()} >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FiltersAndActions