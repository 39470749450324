import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/url'
import AuthContext from '../../Store/auth-context'
import styles from './GettingStarted.module.css'

const GettingStarted = () => {
    const authCtx = useContext(AuthContext)
    const [clientData, setClientData] = useState(null)

    // console.log(authCtx)

    const gettingStarted = async () => {
        const gettingStartedResponse = await fetch(BASE_URL_API + "/gettingStarted",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientIdentifier: authCtx.clientIdentifier,
                    username: authCtx.username
                })
            });

        if (!gettingStartedResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const gettingStartedRespo = await gettingStartedResponse.json()
            if (gettingStartedRespo.status === "success") {
                setClientData(gettingStartedRespo.response)
            } else {
                if (gettingStartedRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(gettingStartedRespo)
        }
    }


    useEffect(() => {
        gettingStarted()
    }, [])


    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ECFAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ECFAFF";
            document.head.appendChild(meta);
        }
    }, []);


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.welcomeBrand}>
                        <h3>Welcome {clientData?.brandName}</h3>
                    </div>
                    <div className={styles.firmName}>
                        <h4>{clientData?.firmName}</h4>
                    </div>
                    <div className={styles.officeAddress}>
                        {clientData?.officeAddress}
                    </div>
                </div>

                <div className={styles.aboutWrapper}>
                    The All-in-One Platform for Customer Engagement, Promotions, and Loyalty
                    Increase revenue, efficiency, and customer satisfaction with Qnnox.
                </div>

                <div className={styles.featuredWrapper}>
                    <div>
                        <div className={styles.featuredTitle}>Featured For You</div>
                        <div className={styles.cardsWrapper}>

                            <Link to={`/orders`} className={styles.eachCardWrapper}>
                                <div>
                                    <div className={styles.topTitle}>Track Your Orders</div>
                                    <div className={styles.crispMsg}>Get real-time update of orders.</div>
                                    <div className={styles.bigMsg}>
                                        Give the real-time update of every orders placed.
                                    </div>
                                </div>
                                <div className={styles.exploreCatalogueBtnWrapper}>
                                    <button className={styles.exploreCatalogueBtn}>
                                        Track Order
                                    </button>
                                </div>
                            </Link>
                            <Link to={`/catalogue`} className={styles.eachCardWrapper}>
                                <div>
                                    <div className={styles.topTitle}>Choose From Widest Collection</div>
                                    <div className={styles.crispMsg}>Share wide range of products</div>
                                    <div className={styles.bigMsg}>
                                        Give your customers / retailers flexiblity to choose from hundreds of products.
                                    </div>
                                </div>
                                <div className={styles.exploreCatalogueBtnWrapper}>
                                    <button className={styles.exploreCatalogueBtn}>
                                        Explore Now
                                    </button>
                                </div>
                            </Link>
                            <div className={styles.eachCardWrapper}>
                                <div>
                                    <div className={styles.topTitle}>Easiest Way To</div>
                                    <div className={styles.crispMsg}>Engage Your Customer</div>
                                    <div className={styles.bigMsg}>
                                        Run smart campaigns and increase retention rate up to 35%
                                    </div>
                                </div>
                                <div className={styles.exploreBtnWrapper}>
                                    <button className={styles.exploreBtn}>
                                        Explore Now
                                    </button>
                                </div>
                            </div>



                            <div className={styles.eachCardWrapper}>
                                <div>
                                    <div className={styles.comingSoonTopTitle}>Smartest Way To</div>
                                    <div className={styles.crispMsg}>Manage Your GST</div>
                                    <div className={styles.bigMsg}>
                                        Raise quotes, invoices to your customers within few clicks.
                                    </div>
                                </div>
                                <div className={styles.comingSoonBtnWrapper}>
                                    <button className={styles.coomingSoonBtn}>
                                        Coming Soon
                                    </button>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default GettingStarted