import React from 'react'
import styles from './MainFooter.module.css'

const MainFooter = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.copyrightTxt}>
                    © Qnnox Rewards | All Rights Reserved 2024
                </div>
                <div className={styles.visitQnnox}>
                    <a href="https://qnnox.com" target="_blank">
                        Visit Qnnox.com
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MainFooter