import React, { useContext, useEffect, useState } from 'react'
import { FaCartPlus, FaPlay, FaPowerOff, FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import AuthContext from '../../Store/auth-context'
import styles from './MainHeader.module.css'
import { MdMenu, MdOutlineClose } from "react-icons/md";
import { RiCustomerService2Fill } from 'react-icons/ri'
import { PiNavigationArrow } from 'react-icons/pi'
import { IoAnalyticsOutline } from 'react-icons/io5'


const MainHeader = () => {
    const authCtx = useContext(AuthContext)
    const [isSliderOpen, setIsSliderOpen] = useState(false)

    window.onclick = event => event.target.id === "menuSlideClose" ? setIsSliderOpen(false) : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            setIsSliderOpen(false)
        }
    });

    useEffect(() => {
        setIsSliderOpen(false)
    }, [window.location.pathname])
    

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.logoWrapper}>
                <Link to="/">
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/master-file-Qnnox-logo_512x512.png" />
                </Link>
            </div>
            <div className={styles.burgerIcon} onClick={() => setIsSliderOpen(true)}>
                <MdMenu size={30} />
            </div>
            <div className={styles.rightSectionWrapper}>
                <div className={styles.userIconWrapper}>
                    <FaUser />
                </div>
                <div className={styles.loginBtnWrapper}>
                    <button className={styles.userIconWrapper} onClick={() => authCtx.logout()}>
                        <FaPowerOff />
                    </button>
                </div>
            </div>

            {isSliderOpen &&
                <div className={styles.sliderWrapper}>
                    <div className={styles.sliderContainer} id="menuSlideClose" >
                        <div className={styles.sliderContent}>
                            <div className={styles.headerWrapper}>
                                <div className={styles.closeIcon} onClick={() => setIsSliderOpen(false)} >
                                    <MdOutlineClose size={28} />
                                </div>
                            </div>
                            <div className={styles.navOptionsWrapper}>
                                <div className={styles.sectionWrapper}>
                                    <div className={styles.sectionTitle}>Dashboard</div>
                                    <div>
                                        <Link to="/getting-started" className={styles.eachNavWrapper}>
                                            <div className={styles.eachNavIcon}>
                                                <FaPlay />
                                            </div>
                                            <div>
                                                Getting Started
                                            </div>
                                        </Link>

                                        <Link to="/analysis" className={styles.eachNavWrapper}>
                                            <div>
                                                <IoAnalyticsOutline />
                                            </div>
                                            <div>
                                                Analysis
                                            </div>
                                        </Link>

                                        <Link to="user-data" className={styles.eachNavWrapper}>
                                            <div>
                                                <FaUser />
                                            </div>
                                            <div>
                                                User Data
                                            </div>
                                        </Link>
                                    </div>

                                </div>
                                <div>
                                    <div className={styles.sectionTitle}>Services</div>
                                    <div>
                                        <Link to={`/catalogue`} className={styles.eachNavWrapper}>
                                            <div>
                                                <FaCartPlus />
                                            </div>
                                            <div>
                                                Catalogue
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.sectionTitle}>Orders</div>
                                    <div>
                                        <Link to={`/orders`} className={styles.eachNavWrapper}>
                                            <div>
                                                <PiNavigationArrow />
                                            </div>
                                            <div>
                                                Track Order
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.sectionTitle}>Account</div>
                                    <div>
                                        <Link to="invoice" className={styles.eachNavWrapper}>
                                            <div>
                                                <FaUser />
                                            </div>
                                            <div>
                                                Invoice
                                            </div>
                                        </Link>

                                        <Link to="gst-details" className={styles.eachNavWrapper}>
                                            <div>
                                                <FaUser />
                                            </div>
                                            <div>
                                                GST Details
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.sectionTitle}>Support</div>
                                    <div>
                                        <div className={styles.eachNavWrapper}>
                                            <div className={styles.eachNavIcon}>
                                                <RiCustomerService2Fill size={20} />
                                            </div>
                                            <div>
                                                Customer Support
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default MainHeader